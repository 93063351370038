.nav-main {
  margin: 0.5rem 0rem;
  padding: 0rem 1.25rem;
  text-align: right;
}
@media only screen and (min-width: 600px) {
  .nav-main {
    margin: 1rem 0rem;
    padding: 0rem 1.5rem;
  }
}

.nav-main button {
  margin-top: 0;
  padding: 5px 10px 5px 10px;
  font-size: 1rem;
}

.header-image {
  width: 200px;
  height: auto;
  margin: auto;
  display: block;
}

@media only screen and (min-width: 600px) {
  .header-image {
    width: 240px;
  }
}
