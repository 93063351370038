.App {
  font-family: "Amatic SC", cursive;
  font-weight: 400;
  color: #333333;
  line-height: 1.15;
  -webkit-text-stroke: 0.55px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

h1 {
  font-weight: 700;
  margin: 0rem;
  font-size: 3.25rem;
  padding: 0rem 0.5rem;
}

h2 {
  font-weight: 700;
  font-size: 2rem;
  margin: 0.75rem 0rem;
}

h3 {
  font-weight: 400;
  font-size: 1.5rem;
  margin: 0.5rem 0rem;
}

button {
  margin-top: 0.75rem;
  background: #e6e6e6;
  border-radius: 6px;
  border: none;
  padding: 7px 15px 7px 15px;
  font: inherit;
  cursor: pointer;
  font-weight: 700;
  color: #333333;
}
