.results {
  text-align: center;
  text-transform: uppercase;
}

.results h2 {
  font-size: 3rem;
}

.results h3 {
  font-size: 2rem;
}
.results img {
  max-height: 180px;
  border-radius: 10px;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
}
.results p {
  font-size: 1.5rem;
  margin: 0rem;
}

.results-score {
  font-weight: 700;
  color: #d43636;
}

.results-rank {
  font-weight: 700;
}
